.switch--vertical
    height 35px
    margin-top: 5px
    margin-right: 0


/* main styles */
.switch
  position relative
  & input
    position absolute
    top 0
    z-index 2
    opacity 0
    cursor pointer
    &:checked
      z-index 1
      & + label
        opacity 1
        color rgba(1, 190, 98, 0.7)
        cursor default
    &:not(:checked) + label:hover
      opacity .7
  & label
    color #86827e;
    opacity .50
    transition opacity .25s ease
    cursor pointer
    margin 0
    padding 0
  & .toggle-outside
    height 100%
    border-radius 1rem
    padding .20rem
    overflow hidden
    transition .25s ease all

  & .toggle-inside
    border-radius 2rem
    position absolute
    transition .25s ease all

  & input
    height 50%
    width 1rem
    left 0
    margin 0
  & label
    display block
    height 50%
    margin   0 0 0 15px
    padding 0 5px
    text-align left
  & .toggle-outside
    background  rgba(134, 130, 126, 0.2)
    position absolute
    width 17px
    height 100%
    left 0
    top 0
  & .toggle-inside
    /*background color rgba(1, 190, 98, 0.7)*/
    height 15px
    width 15px
    left  1px
    top 0
  & input:checked ~ .toggle-outside .toggle-inside
    top 1px
    background rgba(1, 190, 98, 0.5)
    /*background rgba(0,0,0,.2)*/

  & input ~ input:checked ~ .toggle-outside .toggle-inside
    top 20px
    @media (min-width: 768px)
        top 23px
